import * as React from 'react';
import { graphql } from 'gatsby';
import Masonry from 'react-masonry-css';
import InfiniteScroll from 'react-infinite-scroll-component';
import * as Styles from '../styles/pages/_gallery.module.scss';
import * as CommonStyles from '../styles/global/_common.module.scss';
import { Layout } from '../components/layout';
import { Seo } from '../components/seo';
import { BreadCrumb } from '../components/breadcrumb';
import { BackGround } from '../components/background';
import { useLightBox } from '../hooks/use-light-box';
import {useIntl} from "gatsby-plugin-react-intl"

const GalleryPage = ({ data, location }) => {
  const intl = useIntl()
  const t = (id) => intl.formatMessage({id})
  // 画面サイズごとの１行あたりの要素数
  const breakpointColumnsObj = {
    default: 6,
    1350: 6,
    1048: 4,
    576: 2,
  };

  // スクロールが画面外に入った際の読み込み表示数
  const showItems = 20;

  const [showGalleryIndex, setShowGalleryIndex] = React.useState(showItems);

  const [showGallery] = React.useState(data.allMicrocmsIllust.edges);

  const images = data.allMicrocmsIllust.edges.map(({ node }) => {
    return {
      image: `${node.image.url}?fm=webp`,
      title: node.title,
      artist: node.artist
    }
  });

  const [galleryIndex, setGalleryIndex] = React.useState(null);

  const { LightBox, open } = useLightBox('gallery', setGalleryIndex);
  return (
    <Layout location={location}>
      <Seo title={t('gallery.seo.title')} description={t('gallery.seo.description')} />
      <BreadCrumb
        list={[
          {
            title: 'TOP',
            link: '/',
          },
        ]}
        currentPage={t('menu.gallery')}
      />
      <BackGround>
        <section className={Styles.gallery}>
          <div className={Styles.gallery__wrap}>
            <div className={CommonStyles.container}>
              <h1>{t('menu.gallery')}</h1>

              {images.length > 0 ? (
                <>
                  <div>
                    <InfiniteScroll dataLength={showGalleryIndex} next={() => setShowGalleryIndex(showGalleryIndex + showItems)} hasMore={showGallery.length > showGalleryIndex}>
                      <Masonry breakpointCols={breakpointColumnsObj} className={Styles.my_masonry_grid}>
                        {showGallery.slice(0, showGalleryIndex).map(({ node }, index) => (
                          <div key={node.illustId} className={Styles.my_masonry_grid__inner}>
                            <button
                              type="button"
                              onClick={() => {
                                setGalleryIndex(index);
                                open();
                              }}
                            >
                              <img src={node.image.url + '?w=414&fm=webp'} alt="" />
                            </button>
                          </div>
                        ))}
                      </Masonry>
                    </InfiniteScroll>
                  </div>
                  <LightBox images={images} currentIndex={galleryIndex} onMovePrev={() => setGalleryIndex((galleryIndex + images.length - 1) % images.length)} onMoveNext={() => setGalleryIndex((galleryIndex + 1) % images.length)} navigation caption />
                </>
              ) : (
                <p className={Styles.gallery__not_content}>{t('gallery.noEntry')}</p>
              )}
            </div>
          </div>
        </section>
      </BackGround>
    </Layout>
  );
};

export const query = graphql`
  query {
    allMicrocmsIllust(filter: {is_private: {eq: false}}) {
      edges {
        node {
          illustId
          title
          artist {
            name
            id
            image {
              url
            }
          }
          image {
            url
          }
        }
      }
    }
  }
`;

export default GalleryPage;
